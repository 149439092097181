import React from 'react';
import PropTypes from 'prop-types';
import '../main.scss';

const Layout: React.FC = ({ children }) => (
  <>
    <main>
      {children}
    </main>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
