import React from 'react';

export default () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="circle"
      d="M30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60C46.5685 60 60
         46.5685 60 30C60 13.4315 46.5685 0 30 0Z"
    />
    <path d="M25 28V32H23V28H25Z" className="line" fill="#fff" />
    <path d="M37 28V32H35V28H37Z" className="line" fill="#fff" />
    <path d="M19 28V32H17V28H19Z" className="line" fill="#fff" />
    <path d="M31 28V32H29V28H31Z" className="line" fill="#fff" />
    <path d="M13 28V32H11V28H13Z" className="line" fill="#fff" />
    <path d="M43 28V32H41V28H43Z" className="line" fill="#fff" />
    <path d="M49 28V32H47V28H49Z" className="line" fill="#fff" />
  </svg>
);
